import COLORS from '~/lib/helpers/color-helper';

interface Props {
  fillColor?: string;
  height?: number;
  width?: number;
}

export default function ThumbsDown({
  fillColor = COLORS.primary,
  height = 18,
  width = 18,
}: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
      <path
        d="M2.7975 5.8117L2.2688 8.87175C2.2378 9.0511 2.2463 9.23506 2.2938 9.41077C2.3413 9.58648 2.4266 9.74969 2.5437 9.88899C2.6609 10.0283 2.8071 10.1403 2.972 10.2172C3.137 10.2941 3.3168 10.3341 3.4988 10.3342H7.3845C7.4752 10.3342 7.5648 10.354 7.6471 10.3921C7.7294 10.4301 7.8024 10.4857 7.8611 10.5548C7.9198 10.624 7.9627 10.7051 7.9868 10.7925C8.011 10.8799 8.0158 10.9715 8.001 11.061L7.5038 14.0947C7.4229 14.5876 7.4459 15.0918 7.5713 15.5752C7.6755 15.975 7.9838 16.2967 8.3903 16.4272L8.499 16.4625C8.745 16.5412 9.0128 16.5225 9.2445 16.4115C9.369 16.3524 9.4791 16.267 9.5672 16.161C9.6553 16.0551 9.7192 15.9312 9.7545 15.798L10.1115 14.4225C10.2252 13.9848 10.3903 13.5621 10.6035 13.1632C10.9148 12.5805 11.3963 12.1132 11.8973 11.682L12.9765 10.752C13.1264 10.6227 13.2434 10.4597 13.3179 10.2764C13.3925 10.0931 13.4225 9.89467 13.4055 9.6975L12.7965 2.65275C12.7697 2.34125 12.627 2.05098 12.3966 1.83953C12.1663 1.62807 11.865 1.51072 11.5523 1.5105H8.0663C5.4548 1.5105 3.2265 3.33 2.7975 5.8117Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7739 10.8968C15.629 10.9031 15.4872 10.8532 15.3782 10.7575C15.2692 10.6618 15.2014 10.5276 15.1889 10.383L14.4614 1.956C14.4491 1.83058 14.4625 1.70392 14.5008 1.58373C14.5391 1.46354 14.6014 1.35254 14.6841 1.25727C14.7667 1.162 14.8679 1.08473 14.9815 1.02977C15.095 0.974959 15.2186 0.943989 15.3446 0.938504C15.4705 0.933019 15.5963 0.953284 15.7142 0.998116C15.832 1.04295 15.9396 1.11116 16.0302 1.19891C16.1207 1.28665 16.1925 1.39191 16.241 1.50832C16.2896 1.62474 16.3139 1.74969 16.3124 1.87575V10.335C16.3126 10.4802 16.2566 10.6197 16.1563 10.7245C16.0559 10.8294 15.9189 10.8906 15.7739 10.8968Z"
        fill={fillColor}
      />
    </svg>
  );
}
