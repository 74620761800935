import { ChildrenLayoutProps } from 'types';
import { spacing } from '@/lib/helpers/spacing';
import { HTMLAttributes } from 'react';

export interface BoxProps
  extends ChildrenLayoutProps,
    HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  align?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around';
  gap?: number;
}
export function Flex({
  m = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mt = 0,
  p = 0,
  pb = 0,
  pl = 0,
  pr = 0,
  pt = 0,
  gap = 0,
  children,
  direction = 'column',
  align = 'flex-start',
  justify = 'flex-start',
  style,
  ...props
}: BoxProps) {
  const computedStyles = {
    ...(!!m && { margin: spacing.padding * m }),
    ...(!!mb && { marginBottom: spacing.padding * mb }),
    ...(!!ml && { marginLeft: spacing.padding * ml }),
    ...(!!mr && { marginRight: spacing.padding * mr }),
    ...(!!mt && { marginTop: spacing.padding * mt }),
    ...(!!p && { padding: spacing.padding * p }),
    ...(!!pb && { paddingBottom: spacing.padding * pb }),
    ...(!!pl && { paddingLeft: spacing.padding * pl }),
    ...(!!pr && { paddingRight: spacing.padding * pr }),
    ...(!!pt && { paddingTop: spacing.padding * pt }),
    ...(!!gap && { gap: gap }),
    display: 'flex',
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    ...style,
  };

  return (
    <div style={computedStyles} {...props}>
      {children}
    </div>
  );
}
